@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .header {
    width: calc(100% - 60px);
    height: 140px;
    max-width: 1200px;
    padding: 40px 0px 0px 0px;
    margin: auto;
    .back {
      display: flex;
      align-items: center;
      padding: 0px;
      border: none;
      background-color: transparent;
      color: $primary-color;
      cursor: pointer;
      svg {
        font-size: 22px;
        margin-right: 6px;
      }
      p {
        margin: 0px;
        color: $primary-color;
        text-transform: uppercase;
        font-size: 15px;
        @include regular;
      }
    }
    h2 {
      @include semiBold;
      font-size: 28px;
      font-style: normal;
      color: $primary-color;
      font-weight: 500;
      margin: 0px;
    }
  }
  .list {
    margin: auto;
    display: grid;
    padding: 10px 30px 40px 30px;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 1260px;
    max-height: calc(100% - 130px);
    overflow-y: auto;
    @include scrollbar;
  }
}