@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.panel {
  border-bottom: 1px solid rgba($primary-color,0.2);
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      .icon {
        @include flex-center;
        justify-content: flex-start;
        width: 30px;
      }
      h3 {
        margin: 0px;
        font-size: 15px;
        line-height: 14px;
        @include semiBold;
        color: $black;
      }
    }
    .toggle {
      @include reset-btn;
      width: 38px;
      height: 55px;
      @include flex-center;
      font-size: 16px; 
    }
  }
  .panel-content {
    @include transition;
    overflow: hidden;
  }
}