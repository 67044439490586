@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .header {
    width: calc(100% - 60px);
    height: 130px;
    max-width: 1200px;
    padding: 40px 0px 0px 0px;
    margin: auto;
    h2 {
      @include semiBold;
      font-size: 28px;
      font-style: normal;
      color: $primary-color;
      font-weight: 500;
      margin: 0px;
    }
  }
  .list {
    padding: 0px 30px 0px 30px;
    &:not(.labels) {
      max-height: calc(100% - 160px);
      overflow-y: auto;
      @include scrollbar;
      .row {
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        &:hover {
          background-color: rgba(#dce4e3, 0.5);
        }
        p {
          @include semiBold;
          color: $primary-color;
          margin: 0px;
        }
      }
    }
    .row {
      max-width: 1200px;
      border-bottom: 2px solid #dce4e3;
      width: 100%;
      margin: auto;
      display: flex;
      .col {
        min-width: 50%;
        @media only screen and (min-width: $md-screen) {
          min-width: 30%;
        }
        p {
          padding: 0px 6px;
        }
      }
      @media only screen and (min-width: 1200px) {
        padding: 0px;
      }
    }
  }
}