@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.card {
  position: relative;
  width: 100%;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.10);
  border-radius: 13px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  border: 2px solid transparent;
  text-align: left;
  background-color: #F5F5F5;
  cursor: pointer;
  .icon {
    border-radius: 10px;
    height: 70px;
    width: 70px;
    min-width: 70px;
    overflow: hidden;
    @include flex-center;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  &:hover {
    border: 2px solid rgba($primary-color, 0.4);
  }
  &.visit-card {
    cursor: pointer;
    border: 2px solid $white;
    background-color: $white;
    @include transition;
    &:hover {
      border: 2px solid rgba($primary-color, 0.4);
      .delete {
        pointer-events: all;
        opacity: 1;
      }
    }
  }
  .infos {
    padding: 0px 10px;
    max-width: calc(100% - 100px);
    h4 {
      @include bold;
      color: $black;

      font-size: 15px;
      margin: 0px;
    }
    p {
      margin: 0px;
      line-height: 13px;
      &.address {
        margin-top: 5px;
      }
      &.status {
        margin-bottom: 5px;
        @include bold;
        color: $black;
      }
    }
  }
  .toggle {
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 20px;
    @include btn-add;
    color: $primary-color;
    &.visit-active {
      border: 1px solid $primary-color;
      svg {
        opacity: 0;
      }
      &:hover {
        svg {
          opacity: 1;
        }
      }
    }
  }
  .visit {
    position: absolute;
    right: -18px;
    top: 30px;
    border-radius: 20px;
    @include btn-add;
    color: $primary-color;
    width: 38px;
    height: 38px;
    background-color: #d1e0df;
  }
  .delete {
    @include transition;
    position: absolute;
    bottom: 6px;
    right: 8px;
    border: none;
    z-index: 100;
    background-color: $white;
    color: rgba($black, 0.5);
    font-size: 20px;
    pointer-events: none;
    opacity: 0;
    cursor: pointer;
  }
  .pin {
    @include transition;
    position: absolute;
    bottom: 6px;
    right: 8px;
    border: none;
    background-color: transparent;
    color: rgba($black, 0.5);
    font-size: 20px;
    opacity: 1;
    cursor: pointer;
  }
}