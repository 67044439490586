@import '../../../styles/_variables.scss';
@import '../_form-mixin.scss';

.container-input-email{

  &:not(.login) {
    label{
      @include label;
    }
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
  }
  &.secondary {
    @include secondary;
  }
  &.primary {
    @include primary;
  }
  &.login {
    @include login;
    @include label-secondary;
  }
  
}
