@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.reset-password {
  @include authTemplate;
  .content {
    .form {
      .logo {
        margin-bottom: 10px;
      }
    }
  }
}