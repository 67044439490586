
@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';

@mixin inline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  label{
    margin-right: 10px;
  }
}

@mixin label-secondary {
  @include regular;
  font-size: $regular;
  line-height: 17px; 
  color: $text-light;
  white-space: nowrap;
}

@mixin text {
  @include regular;
  font-weight: 400;
  font-size: $regular;
  line-height: 17px;
  color: $text-color;
}

@mixin input {
    @include regular;
    display: block;
    border: none;
    outline: none;
    border-radius: 0px;
    width: 100%;
    font-size: 13px;
    // min-width: 0px;
    box-sizing: border-box;
    background-color: $inputBg;
    color: $inputColor;
    padding: 0px 18px;
    min-height: 43px;
    border-radius: 30px;
    border: 2px solid #EAF1F0;


    // @media screen and (min-width: $sm-screen) {
    //   min-width: 220px;
    // }

    &::placeholder {
      color : rgba($text-light, 0.6);
    }
}

@mixin tagform {
  @include tag;
}

@mixin primary {
  .input {
    padding: 12px 18px;
    border: 2px solid  #e1e7e6;
    background-color: #F5F5F5;
  }
  .select {
    @include input;
    padding: 12px 18px;
    border: 2px solid  #e1e7e6;
    background-color: #F5F5F5;
  }
}


@mixin secondary {
  .input {
    border: solid 1px $white;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    padding: 10px 10px;
    border-radius: 5px;
  }
}

@mixin login {
  .input {
    @include input;
    padding: 10px 12px;
    font-size: $regular;
    line-height: 17px;
  }
};