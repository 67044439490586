@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";


.container {
  width: 100%;
  .appointment {
    &.desktop {
      display: none;
    }
    &.mobile {
      background-color: $white;
      border: 2px solid var(--vert-clair, #EAF1F0);
      border-radius: 13px;
      position: relative;
      margin-bottom: 10px;
      .infos {
        display: flex;
        align-items: center;
        padding: 10px;
        p {
          margin: 0px;
          margin-right: 10px;
          &:first-child {
            padding-right: 10px;
            border-right: 1px solid $text-color;
          }
        }
      }
      .comment {
        padding: 0px 10px;
        p {
          color: $black;
          &:last-child {
            margin: 0px;
            color: $text-color;
            padding-bottom: 10px;
          }
        }
      }
      button {
        position: absolute;
        cursor: pointer;
        right: 5px;
        top: 8px;
        background-color: transparent;
        color: $primary-color;
        font-size: 20px;
        border: none;
      }
    }
    @media only screen and (min-width: $xl-screen) {
      &.mobile {
        display: none;
      }
      &.desktop {
        display: flex;
        position: relative;
        border-bottom: 2px solid var(--vert-clair, #d7e8e5);
        width: 100%;
        padding: 10px 0px;
        .infos {
          width: 25%;
          padding: 0px 20px;
          p {
            color: $primary-color;
            margin: 0px;
            &:first-child {
              color: $black;
              @include semiBold;
            }
          }
        }
        .comment {
          border-left: 2px solid var(--vert-clair, #d7e8e5);
          padding-left: 20px;
          width: 60%;
          p {
            color: $black;
            margin: 0px;
            @include transition;
            overflow: hidden;
            &:first-child {
              color: $black;
              @include semiBold;
            }
            &.ellipsis {
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          span {
            width: 60%;
            &.hidden {
              min-height: 150px;
              display: block;
              position: absolute;
              opacity: 0;
              pointer-events: none;
            }
          }
        }
        button {
          position: absolute;
          right: 0px;
          top: 8px;
          cursor: pointer;
          background-color: transparent;
          color: $primary-color;
          font-size: 16px;
          border: none;
        }
      }
    }
  }
}
