@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  overflow: hidden;
  .content {
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    overflow: auto;
    height: 100%;
    @include scrollbar;
    h2 {
      display: none;
      @include semiBold;
      font-size: 28px;
      font-style: normal;
      color: $primary-color;
      font-weight: 500;
      margin: 30px 0px 10px 0px;
      @media only screen and (min-width: $lg-screen) {
        display: block;
      }
    }
    .col {
      width: calc(100%);
      position: relative;
      padding: 0px 20px;
      @media only screen and (min-width: $lg-screen) {
        width: calc(50% - 20px);
        padding: 0px 50px;
      }
      &:nth-child(2) {
        background-color: $white;
        height: 100%;
        border: 2px solid  #EAF1F0;
        display: none;
        @media only screen and (min-width: $lg-screen) {
          display: block;
        }
      }
      .container-field {
        margin-bottom: 15px;
        @media only screen and (min-width: $md-screen) {
          max-width: 380px;
        }
        .error {
          margin-left: 20px;
          min-height: 20px;
        }
      }
      .picture {
        position: relative;
        background-color: #62969D;
        width: 200px;
        height: 200px;
        border-radius: 12px;
        @include flex-center;
        overflow: hidden;
        margin-bottom: 30px;
        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .icon {
          position: absolute;
          @include flex-center;
          cursor: pointer;
          top: 6px;
          right: 6px;
          width: 42px;
          height: 42px;
          border-radius: 22px;
          background-color: $primary-color;
          img {
            transform: translate(-2px , -2px)
          }
        }
        p {
          @include semiBold;
          color: $white;
          font-size: 88px;
          margin: 0px;
        }
      }
      .logout {
        margin-top: 20px;
        border-radius: 5px;
        background-color: transparent;
        @include semiBold;
        display: flex;
        align-items: center;
        color: $primary-color;
        cursor: pointer;
        border: none;
        &:hover {
          text-decoration: underline;
        }
        svg {
          font-size: 20px;
          margin-left: 10px;
        }
        @media only screen and (min-width: $lg-screen) {
          top: 20px;
          right: 0px;
        }
      }
      .submit {
        margin-top: 10px;
        @include btn;
        border-radius: 5px;
        @media only screen and (min-width: $lg-screen) {
          position: absolute;
          top: 20px;
          right: 0px;
        }
      }
    }
  }
}