@import "../../styles/_variables.scss";
@import "../../styles/mixins";


.container {
  width: 100%;
  border: 2px solid rgba($primary-color, 0.2);
  background-color: #fcfcfc;
  padding: 20px;
  border-radius: 13px;
  position: relative;
  .toggle {
    position: absolute;
    right: 15px;
    top: 15px;
    @include btn;
    border-radius: 5px;
    width: 93px;
    padding: 6px;
  }
  .form {
    h3 {
      @include semiBold;
      color: $black;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 16px;
    }
    section {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0px;
      }
      p {
        span {
          opacity: 0.3;
        }
      }
    }
    .contact { 
      display: flex;
      .icon {
        width: 20px;
        @include flex-center;
        justify-content: flex-start;
        color: $primary-color;
      }
      p {
        @include semiBold;
        color: $black;
        margin-right: 5px;
        margin-bottom: 0px;
      }
    }
    .info { 
      display: flex;
      .icon {
        width: 20px;
        @include flex-center;
      }
      p {
        @include semiBold;
        color: $black;
        margin-right: 5px;
        margin-bottom: 6px;
        &:first-child {
          color: $primary-color;
          @include semiBold;
        }
      }
    }
    .form-field {
      @media only screen and (min-width: $md-screen) {
        display: flex;
        margin-bottom: 10px;
      }
      label {
        color: $primary-color;
        @include semiBold;
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
      .input {
        margin-top: 5px;
        margin-bottom: 10px;
        border: 1px solid rgba($primary-color, 0.2);
        border-radius: 5px;
        @media only screen and (min-width: $md-screen) {
          margin-top: 0px;
          min-width: 180px;
          margin-bottom: 0px;
          margin-left: 6px;
        }
      }
      .boolean {
        margin-top: 5px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        @media only screen and (min-width: $md-screen) {
          width: 200px;
          margin-top: 0px;
          margin-bottom: 0px;
          margin-left: 6px;
        }
        button {
          @include reset-btn;
          @include regular;
          width: calc(50% - 5px);
          padding: 8px;
          text-align: center;
          border: 1px solid rgba($primary-color, 0.2);
          border-radius: 5px;
          font-size: 14px;
          cursor: pointer;
          &.active {
            background-color: $primary-color;
            color: $white;
          }
        }
      }
    }
    .picture-field {
      width: 100%;
      label {
        color: $primary-color;
        @include semiBold;
        display: flex;
        align-items: center;
        svg {
          margin-right: 6px;
        }
      }
      .empty-picture {
        width: 100%;
        margin-top: 10px;
        min-height: 120px;
        @include flex-center;
        border-radius: 5px;
        border: 1px solid rgba($primary-color, 0.2);
        p {
          @include btn;
          margin: 0px;
          padding: 6px 16px;
          font-size: 13px;
        }
      }
      .picture {
        width: 100%;
        margin-top: 10px;
        position: relative;
        @include flex-center;
        overflow: hidden;
        border-radius: 13px;
        @media only screen and (min-width: $md-screen) {
          max-height: 200px;
        }
        .icon {
          position: absolute;
          @include flex-center;
          cursor: pointer;
          top: 6px;
          right: 6px;
          width: 42px;
          height: 42px;
          border-radius: 22px;
          background-color: $primary-color;
          img {
            max-width: 20px;
            object-fit: contain;
            transform: translate(-2px , -2px)
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 13px;
          object-fit: cover;
        }
      }
    }
  }
}