@import "../../styles/_variables.scss";
@import "../../styles/mixins";


.container {
  display: flex;
  align-items: center;
  margin:  5px 5px;
  button {
    background-color: transparent;
    border: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    @include flex-center;
    font-size: 22px;
    padding: 0px;
    color: $primary-color;
    cursor: pointer;
    &.reset {
      color: rgba($black, 0.6);
    }
  }
  .input {
    display: flex;
    align-items: center;
    width: 0px;
    overflow: hidden;
    @include transition;
    &.isOpen {
      width: 240px;
    }
    .icon {
      width: 30px;
      height: 30px;
      @include flex-center;
      font-size: 22px;
      color: $primary-color;
    }
    input {
      @include bold;
      background-color: transparent;
      border: none;
      font-size: 14px;
      color: $primary-color;
      &::placeholder {
        color: rgba($black, 0.3);
      }
    }
  }
}