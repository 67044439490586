@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.layout{
  display: flex;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
  background-color: #F5F5F5;
  .container {
    height: calc(100vh - 56px);
    width: 100%;
    overflow-y: auto;
    @media only screen and (min-width: $md-screen) {
      @include scrollbar;
    }
    @media only screen and (min-width: $xl-screen) {
      height: calc(100vh - 76px);
      margin-top: 76px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba($primary-color, 0.5);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $primary-color;
    }
  }
}