@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-select{

  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label {
      margin-bottom: 10px;
    }
  }

  .input {
    width: 100%;
    padding: 0px;
    // min-width: 200px;
  }
  &.primary {
    @include primary;
    .input {
      padding: 3px 10px;
      border-radius: 30px;
      height: 47.5px;
    }

  }

}
