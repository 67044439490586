@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  width: 100%;
  margin-top: 0px;
  height: calc(100vh - 132px);
  @media only screen and (min-width: $xl-screen) {
    margin-top: 0px;
    height: calc(100vh - 76px);
  }
  .loader {
    @include flex-center;
    height: 100%;
  }
}