@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";


.container {
  width: 100%;
  margin-bottom: 20px;
  @media only screen and (min-width: $xl-screen) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
  .col {
    margin-top: 20px;
    width: 100%;
    @media only screen and (min-width: $xl-screen) {
      margin-top: 0px;
      width: 40%;
      &:first-child {
        width: 60%;
      }
    }
  }
  .field {
    display: flex;
    width: 100%;
    label {
      display: block;
      color: #6A797C;
      width: 40%;
    }
    p {
      color: $black;
      &.bold {
        @include semiBold;
      }
    }
  }
  .salesPoint{
    margin: 10px 20px 0px 0px;
    padding: 15px 20px;
    border: 1px solid rgba($primary-color, 0.2);
    border-radius: 10px;
    p {
      margin: 0px;
      &.bold {
        @include semiBold;
        color: $black;
      }
    }
  }
}

.panel {
  border-bottom: 1px solid rgba($primary-color,0.2);
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      .icon {
        @include flex-center;
        justify-content: flex-start;
        width: 30px;
      }
      h3 {
        margin: 0px;
        font-size: 15px;
        line-height: 14px;
        @include semiBold;
        color: $black;
      }
    }
    .toggle {
      @include reset-btn;
      width: 38px;
      height: 55px;
      @include flex-center;
      font-size: 16px; 
    }
  }
  .panel-content {
    @include transition;
    overflow: hidden;
  }
}