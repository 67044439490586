@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  .list {
    height: calc(100% - 60px);
    overflow-y: auto;
    @include scrollbar;
    .cards {
      display: grid;
      gap: 20px;
      padding: 10px 20px 20px 20px;
    }
    .loader {
      @include flex-center;
    }
  }
  .mobile {
    height: 100%;
    @include flex-center;
    p {
      color: $primary-color;
      font-size: 14px;
    }
  }
  .desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 160px;
      max-width: 1200px;
      padding: 40px 30px 0px 30px;
      margin: auto;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          @include semiBold;
          font-size: 28px;
          font-style: normal;
          color: $primary-color;
          font-weight: 500;
          margin: 0px;
        }
        a {
          @include btn;
          border-radius: 5px;
          display: flex;
          align-items: center;
          text-decoration: none;
          svg {
            margin-right: 8px;
          }
        }
      }
      .filters-container {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #dce4e3;
        .filters {
          width: 40%;
          display: flex;
          align-items: center;
          .filter {
            width: 50%;
            border-right: 2px solid #dce4e3;
          }
        }
      }
    }
    .content {
      width: 100%;
      height: calc(100% - 160px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      .list-container {
        height: 100%;
        width: 100%;
        max-width: 1200px;
        padding: 10px 30px 0px 30px;
        .loader {
          @include flex-center;
          height: 478px;
        }
        .cards {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
        }
        .list {
          display: flex;
          flex-direction: column;
          width: 100%;
          .row {
            width: 100%;
            display: flex;
            align-items: center;
            border-bottom: 2px solid #dce4e3;
            height: 45px;
            text-decoration: none;
            &.labels {
              height: 40px;
            }
            &:not(.labels) {
              .col {
                p {
                  color: $black;
                }
              }
              &:hover {
                background-color: rgba($primary-color, 0.05);
              }
            }
            .col {
              width: calc((100% - 70px) / 5);
              display: flex;
              .role {
                padding: 0px 12px;
                height: 28px;
                border-radius: 16px;
                @include flex-center;
                &.Admin {
                  background-color: $admin-color;
                  color: $black;
                  svg {
                    margin-right: 5px;
                    font-size: 16px;
                  }
                }
                &.Member {
                  background-color: #B9D9DB;
                  color: $black;
                }
              }
              .picture {
                width: 36px;
                height: 36px;
                @include flex-center;
                text-align: center;
                @include semiBold;
                border-radius: 5px;
                font-size: 16px;
                text-transform: uppercase;
                overflow: hidden;
                img {
                  width: 100%;
                  height: 100%;
                }
                &.Admin {
                  background-color: $admin-color;
                  color: $black;
                }
                &.Member {
                  background-color: rgba($primary-color, 0.6);
                  color: $white;
                }
              }
              &:first-child {
                width: 70px;
              }
              p {
                margin: 0px;
                text-overflow: ellipsis;
                max-height: 20px;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                &.name {
                  @include semiBold;
                  color: $black;
                }
              }
            }
          }  
        }
      }
    }
  }
}