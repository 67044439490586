@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  overflow: hidden;
  .search {
    width: 100%;
    position: relative;
    padding: 5px 25px;
    img {
      position: absolute;
      top: 20px;
      left: 36px;
    } 
    input {
      width: 100%;
      border-radius: 30px;
      border: 2px solid  #EAF1F0;
      background: #FAFCFB;
      height: 50px;
      padding: 12px 12px 12px 40px;
    }
  }
  .list {
    height: calc(100% - 60px);
    overflow-y: auto;
    @include scrollbar;
    .title {
      padding: 10px 25px 5px 25px;
      display: flex;
      justify-content: space-between;
      p {
        margin: 0px;
        @include semiBold;
        color: $black;
      }
      button {
        @include flex-center;
        cursor: pointer;
        @include regular;
        font-size: 13px;
        border: none;
        background-color: transparent;
        color: rgba($black, 0.5);
        text-decoration: underline;
        svg {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
    .cards {
      display: grid;
      gap: 20px;
      padding: 10px 40px 20px 20px;
    }
    .loader {
      @include flex-center;
    }
  }
  .desktop {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100%);
    .col {
      width: 65%;
      height: 100%;
      overflow-y: auto;
      @include scrollbar;
      &:last-child {
        width: 35%;
        background-color: $white;
        border-left: 2px solid #dce4e3;
        padding-top: 120px;
        .list {
          .title {
            width: 100%;
            max-width: 440px;
          }
          .cards {
            width: 100%;
            max-width: 440px;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
          }
        }
      }
      .header {
        width: 100%;
        max-width: 800px;
        width: 100%;
        padding: 60px 0px 5px 25px;
        margin: auto;
        display: flex;
        align-items: center;
        h2 {
          @include semiBold;
          font-size: 28px;
          font-style: normal;
          color: $primary-color;
          font-weight: 500;
          margin: 0px;
          line-height: 26px;
        }
        .search {
          margin-top: 6px;
          width: 300px;
          position: relative;
          img {
            position: absolute;
            top: 16px;
            left: auto;
            right: 32px;
          } 
          input {
            width: 100%;
            border-radius: 0px;
            border: none;
            border-bottom: 2px solid  #dce4e3;
            background: transparent;
            color: $primary-color;
            height: 40px;
            font-size: 14px;
            padding: 8px 8px 8px 8px;
          }
        }
      }
      .list {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          width: 100%;
          max-width: 800px;
          padding: 10px 30px 5px 25px;
        }
        .cards {
          width: 100%;
          max-width: 800px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }
        .empty {
          min-height: 60vh;
          @include flex-center;
          flex-direction: column;
          p {
            color: $primary-color;
            text-align: center;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}