@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px 30px 0px 0px;
  background-color: $white;
  box-shadow: 0px -2px 37px 0px rgba(0, 0, 0, 0.10);
  @media only screen and (min-width: $xl-screen) {
    top: 0px;
    height: 78px;
    border-radius: 0px;
    padding: 0px;
  }
  .mobile {
    display: flex;
    width: 100%;
    @media only screen and (min-width: $xl-screen) {
      display: none;
    }
    a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      width: 33%;
      .link {
        @include transition;
        width: 36px;
        height: 36px;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon {
          width: 36px;
          @include transition;
          height: 36px;
          min-width: 36px;
          border-radius: 36px;
          @include flex-center;
          .active-img {
            display: none;
          }
        }
        p {
          padding: 0px 6px;
          margin: 0px;
          text-decoration: none;
          @include semiBold;
          font-size: 13px;
          line-height: 14px;
          color: $primary-color;
          @media only screen and (min-width: 460px) {
            font-size: 14px;
            width: 90%;
            padding: 0px;
            margin-right: 10px;
            text-align: center;
          }
        }
      }
      &.active {
        .link {
          background-color: #EAF1F0;
          border-radius: 36px;
          @media only screen and (min-width: 360px) {
            width: 120px;
          }
          .icon {
            background-color: $primary-color;
            .active-img {
              display: block;
            }
            .img {
              display: none;
            }
          }
        } 
      }
    }
  }
  .desktop {
    display: none;
    @media only screen and (min-width: $xl-screen) {
      display: flex;
      justify-content:  space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0px 20px;
    }
    .logo {
      width: 230px;
      img {
        width: 200px;
      }
    }
    .links {
      width: 430px;
      height: 100%;
      display: flex;
      a {
        @include flex-center;
        width: 50%;
        height: 100%;
        @include semiBold;
        text-decoration: none;
        color: rgba($primary-color, 0.6);
        border-bottom:  3px solid transparent;
        font-size: 14px;
        &.active {
          color: $primary-color;
          border-bottom:  3px solid $primary-color;
        }
      }

    }
    .user {
      width: 260px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .tour {
        margin-right: 10px;
        padding: 8px 15px;
        border-radius: 20px;
        border:  2px solid #EAF1F0;
        text-decoration: none;
        display: flex;
        align-items: center;
        @include transition;
        p {
          margin: 0px;
          line-height: 13px;
          font-size: 13px;
          min-width: 80px;
          text-transform: uppercase;
          color: $primary-color;
          @include semiBold;
        }
        .count {
          @include flex-center;
          height: 20px;
          padding: 0px 6px;
          margin-left: 8px;
          background-color: $admin-color;
          border-radius: 5px;
          color: $primary-color;
        }
        &.active {
          background-color: $primary-color;
          border:  2px solid $primary-color;
          p {
            color: $white;
          }
        }
      }
      .notifs {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        border-radius: 40px;
        @include flex-center;
        &.active {
          background-color: #EAF1F0;
        }
      }
    }
  }
}