@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  .list {
    height: calc(100% - 60px);
    overflow-y: auto;
    @include scrollbar;
    .cards {
      display: grid;
      gap: 20px;
      padding: 10px 20px 20px 20px;
    }
    .loader {
      @include flex-center;
    }
  }
  .desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      height: 160px;
      max-width: 1200px;
      padding: 40px 30px 0px 30px;
      margin: auto;
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          @include semiBold;
          font-size: 28px;
          font-style: normal;
          color: $primary-color;
          font-weight: 500;
          margin: 0px;
        }
        .views {
          border: 2px solid #dce4e3;
          display: flex;
          flex-direction: row;
          border-radius: 5px;
          button {
            @include flex-center;
            cursor: pointer;
            background-color: transparent;
            color: rgba($primary-color, 0.6);
            font-size: 18px;
            border: none;
            width: 41px;
            height: 40px;
            &:not(:first-child) {
              border-left: 2px solid #dce4e3;
            }
            &:hover {
              background-color: rgba($primary-color, 0.1);
            }
            &.active {
              background-color: $white;
              color: $primary-color;
            }
          }
        }
      }
      .filters-container {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #dce4e3;
        .filters {
          width: 62%;
          display: flex;
          align-items: center;
          .filter {
            width: 33.33%;
            border-right: 2px solid #dce4e3;
          }
        }
      }
    }
    .content {
      width: 100%;
      height: calc(100% - 160px);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow: auto;
      .list-container {
        height: 100%;
        width: 100%;
        max-width: 1200px;
        padding: 10px 30px 0px 30px;
        .loader {
          @include flex-center;
          height: 478px;
        }
        .cards {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
        }
        .list {
          display: flex;
          flex-direction: column;
          width: 100%;
          .row {
            width: 100%;
            padding: 0px 4px 0px 8px;
            height: 40px;
            display: flex;
            align-items: center;
            border-bottom: 2px solid #dce4e3;
            &:not(.labels) {
              cursor: pointer;
              &:hover {
                background-color: rgba(#dce4e3, 0.5);
              }
              .col {
                p {
                  color: $black;
                }
              }
            }
            .col {
              width: 20%;
              display: flex;
              align-items: center;
              .pin {
                cursor: pointer;
                @include flex-center;
                background-color: transparent;
                border: none;
                margin: 0px 3px 0px 0px;
                height: 26px;
                width: 26px;
                border-radius: 15px;
                &:hover {
                  background-color: rgba($primary-color, 0.2);
                  color: $primary-color;
                }
              }
              &.status {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px;
                @include semiBold;
                .toggle {
                  border-radius: 20px;
                  @include btn-add;
                  color: $primary-color;
                  @include flex-center;
                }
              }
              &:first-child {
                width: 30%;
              }
              &:nth-child(2) {
                width: 30%;
              }
              p {
                margin: 0px;
                text-overflow: ellipsis;
                max-height: 20px;
                width: 90%;
                white-space: nowrap;
                overflow: hidden;
                &.name {
                  @include semiBold;
                  color: $black;
                }
              }
            }
          }  
        }
      }
      .map-container {
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 1;
        position: relative;
      }
    }
  }
  .map-container-mobile {
    height: calc(100vh - 145px);
    width: 100%;
    overflow: hidden;
    z-index: 0;
    position: absolute;
    bottom: 0px;
  }
}