@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  @include container;
  .list {
    height: calc(100% - 60px);
    overflow-y: auto;
    @include scrollbar;
    .cards {
      display: grid;
      gap: 20px;
      padding: 10px 20px 20px 20px;
    }
    .loader {
      @include flex-center;
    }
  }
  .mobile {
    height: 100%;
    @include flex-center;
    p {
      color: $primary-color;
      font-size: 14px;
    }
  }
  .desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    .header {
      width: 100%;
      padding: 40px 30px 40px 30px;
      max-width: 1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        color: $primary-color;
        text-decoration: none;
        display: flex;
        align-items: center;
        svg { 
          font-size: 28px;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        .delete {
          background-color: transparent;
          margin-right: 20px;
          border: none;
          color: $warn;
          @include regular;
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            font-size: 16px;
            margin-right: 4px
          }
        }
        .btn {
          @include btn;
          border-radius: 5px;
        }
      }
    }
    .content {
      z-index: 2;
      width: 100%;
      padding: 0px 30px 40px 30px;
      max-width: 1200px;
      height: calc(100% - 125px);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      overflow: auto;
      .col {
        width: calc(((100% - 250px) / 2) - 10px);
        height: 200px;
        &:first-child {
          width: calc(250px);
        }
        .container-field {
          margin-bottom: 15px;
          .error {
            margin-left: 20px;
            min-height: 20px;
          }
        }
        .picture {
          position: relative;
          background-color: #62969D;
          width: 200px;
          height: 200px;
          border-radius: 12px;
          @include flex-center;
          overflow: hidden;
          &.admin {
            background-color: $admin-color;
            p {
              color: $primary-color;
            }              
          }
          .img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .icon {
            position: absolute;
            @include flex-center;
            cursor: pointer;
            top: 6px;
            right: 6px;
            width: 42px;
            height: 42px;
            border-radius: 22px;
            background-color: $primary-color;
            img {
              transform: translate(-2px , -2px)
            }
          }
          p {
            @include semiBold;
            color: $white;
            font-size: 88px;
            margin: 0px;
          }
        }
      }
    }
    .illu {
      position: absolute;
      bottom: -20px;
      right: 30px;
      z-index: 1;
    }
  }
}