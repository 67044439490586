@mixin regular {
  font-family: "Poppins-Regular", sans-serif;
}

@mixin bold {
  font-family: "Montserrat-Bold";
}


@mixin semiBold {
  font-family: "Poppins-SemiBold", sans-serif;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($primary-color, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin label {
  @include regular;
  font-size: 13px;
  line-height: 13px; 
  color: $text-color;
  white-space: nowrap;
  display: block;
  margin-left: 20px;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}

@mixin reset-btn {
  background-color: transparent;
  padding: 0px;
  border: none;
}
@mixin btn {
  @include semiBold;
  @include transition;
  align-items: center;
  justify-content: center;
  font-size: $button;
  background-color: $primary-color;
  padding: 11px 20px;
  color: $white;
  border: solid 1px $primary-color;
  border-radius: 50px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    top: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader > div {
      position: initial;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 10px;
  }

  &.secondary {
    background-color: $secondary-color;
    border: solid 1px $secondary-color;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.grey {
    background-color: #BCC2C5;
    border: solid 1px #BCC2C5;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.black {
    background-color: $black;
    border: solid 1px $black;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.light {
    background-color: rgba(113, 122, 151, 0.04);
    border: solid 1px rgba(113, 122, 151, 0.04);
    color: $text-color;
  }

  &.third {
    background-color: $white;
    border: solid 1px $text-color;
    color: $text-color;
  }

  &.dashed {
    background-color: rgba(#96A5B8, 0.2);
    border: 1px dashed rgba($text-color, 0.2);
    color: $text-color;
    svg {
      color: $text-color;
    }
    >span {
      text-decoration: underline;
    }
  }


  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin container {
  width: 100%;
  margin-top: 76px;
  height: calc(100vh - 132px);
  @media only screen and (min-width: $xl-screen) {
    margin-top: 0px;
    height: calc(100vh - 76px);
  }
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $margin-xs;
  border-radius: 50%;
  background-color: $primary-color;
  border: 2px solid #f0f0f0;
  background-position: center;
  background-size: cover;

  > span {
    position: relative;
    font-family: "DMSans";
    font-size: $medium;
    color: $white;
  }
}

@mixin containerField {
  margin-bottom: 20px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin content {
  height: calc(100vh - 125px);
  width: 100%;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $xl-screen) {
    background-color: #F6F7F8;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
}

@mixin authTemplate {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $primary-color;
  @media only screen and (min-width: $lg-screen) {
    flex-direction: row;
    overflow: hidden;
    background-color: transparent;
  }
  @media only screen and (min-width: 1200px) {
    background-image: url('../assets/images/bg-login.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
  }
  .picture {
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;
    @include flex-center();
    align-items: flex-end;
    pointer-events: none;
    @media only screen and (min-width: $lg-screen) {
      width: calc(50% - 40px);
      height: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 0px;
      background-color: $primary-color;
      border-radius: 85px;
    }
    img {
      width: 100%;
      transform: translateY(90px);
      max-height: 500px;
      @media only screen and (min-width: $sm-screen) {
        width: 90%;
        max-height: 500px;
      }
      @media only screen and (min-width: $lg-screen) {
        position: absolute;
        bottom: 20px;
        z-index: 2;
        max-height: 70vh;
        transform: translate(-60px , 115px);
      }
      @media only screen and (min-width: 1200px) {
        max-height: 85vh;
        transform: translateY(140px);
      }
    } 
  }

  .content {
    width: 100%;
    height: 60vh;
    z-index: 1;
    overflow: auto;
    background-color: $white;
    border-top-left-radius: 52px;
    border-top-right-radius: 52px;
    display: flex;
    justify-content: center;
    @media only screen and (min-width: $lg-screen) {
      width: 50%;
      height: auto;
      background-color: transparent;
    } 
    .form {
      width: 100%;
      max-width: 350px;
      padding: $margin-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .logo {
        margin-bottom: 30px;
        @media only screen and (min-width: $lg-screen) {
          width: 100%;
          img {
            width: 108%;
          }
        }
      }
      h3 {
        font-size: 16px;
      }
      button {
        @include btn;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}

@mixin btn-add {
  border: none;
  background-color: rgba($primary-color, 0.2);
  @include flex-center();
  cursor: pointer;
  width: 28px;
  height: 28px;
  font-size: 20px;
  &.active {
    background-color: $primary-color;
    color: $white;
  }
}

@mixin btn-edit {
  border: none;
  background-color: transparent;
  @include flex-center();
  cursor: pointer;
  span {
    display: none;
    @include semiBold();
    margin-left: 10px;
    color: $primary-color;
    text-transform: uppercase;
  }
  .icon {
    background-color: #A2B1CE;
    width: 41px;
    height: 41px;
    border-radius: 22px;
    border: none;
    @include flex-center();
  }
  @media only screen and (min-width: $xl-screen) {
    .icon {
      background-color: #A2B1CE;
      width: 32px;
      height: 32px;
      border-radius: 22px;
      border: none;
      @include flex-center();
    }
    span {
      display: block;
    }
  }
}
@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
