@import "../../styles/_variables.scss";
@import "../../styles/mixins";
.custom-marker-cluster {
  background-color: #a2babf;
  @include transition;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.20);
  border: 2px solid $primary-color;
  span {
    @include semiBold;
    color: $primary-color;
  }
}

// .leaflet-popup  {

// }

// .leaflet-popup-content-wrapper {

// }

.leaflet-popup-content {
  width: 300px;
  margin: 0px;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none;
}

.container {
  height: 100%;
  width: 100%;
} 