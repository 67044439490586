@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 100%;
  padding: 0px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  .search {
    width: calc(100% - 70px);
    position: relative;
    img {
      position: absolute;
      top: 15px;
      left: 16px;
    } 
    input {
      width: 100%;
      border-radius: 30px;
      border: 2px solid  #EAF1F0;
      background: #FAFCFB;
      height: 50px;
      padding: 12px 12px 12px 40px;
    }
  }
  .toggle {
    @include btn;
    min-width: 50px;
    max-width: 50px;
    display: flex;
    overflow: hidden;
  }
  .panel {
    top: 0px;
    left: 0px;
    z-index: 100;
    overflow-y: auto;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: $primary-color;
    transform: translateX(100%);
    color: $white;
    @include transition;
    &.open {
      transform: translateX(0px);
    }
    .close {
      background-color: $primary-color;
      border: none;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      svg {
        color: $white;
        font-size: 45px;
        margin-right: 10px;
      }
      p {
        @include bold;
        margin: 0px;
        font-size: 15px;
        color: $white;
      }
    }
    .filters {
      padding: 10px 20px;
      label {
        @include bold;
      }
      .search {
        width: 100%;
        max-width: 450px;
        margin: 10px 0px 10px 0px;
        input {
          width: 100%;
          height: 40px;
          padding: 0px 16px;
          background-color: transparent;
          border: 1px solid $white;
          color: $white;
          font-size: 14px;
          &::placeholder {
            color: rgba($white,0.6);
          }
        }
        button {
          position: absolute;
          right: 5px;
          top: 5px;
          background-color: $white;
          border: none;
          color: $primary-color;
          height: 30px;
          width: 30px;
          border-radius: 15px;
          @include regular;
          @include flex-center;
          font-size: 16px;
        }
      }
      .list {
        margin-top: 10px;
        margin-bottom: 30px;
        button {
          border: 1px solid $white;
          border-radius: 20px;
          padding: 8px 15px;
          margin: 0px 10px 10px 0px;
          background-color: transparent;
          color: $white;
          @include regular;
          &.active {
            background-color: $white;
            color: $primary-color;
          }
        }
      }
    }
    .btn-container {
      position: absolute;
      bottom: 30px;
      width: 100%;
      display: none;
      @media only screen and (min-height: 700px) {
        display: flex;
        justify-content: center;
      }
      button {
        border: 1px solid $white;
        border-radius: 25px;
        padding: 12px 30px;
        margin: 0px 10px 10px 0px;
        @include regular;
        background-color: $white;
        color: $primary-color;
      }
    }
  }
}