@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: relative;
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 35px;
    margin: 0px 6px;
    button {
      @include flex-center;
      background-color: transparent;
      border: none;
      padding: 0px;
      cursor: pointer;
    }
    p {
      margin: 0px 5px;
      width: 80%;
      line-height: 10px;
      font-size: 14px;
      cursor: pointer;
      span {
        @include semiBold;
        color: $primary-color;
      }
    }
    .count {
      position: absolute;
      right: 34px;
      @include flex-center;
      background-color: $primary-color;
      border-radius: 20px;
      min-width: 18px;
      height: 18px;
      font-size: 11px;
      color:$white
    }
    .toggle {
      @include flex-center;
      width: 28px;
      height: 28px;
      cursor: pointer;
      position: absolute;
      right: 3px;
      img {
        margin: 0px;
        @include transition;
      }
      &.open {
        img {
          transform: rotate(180deg);
        }
      }
    }
  }
  .dropdown {
    background-color: $white;
    z-index: 2;
    width: 100%;
    position: absolute;
    overflow: hidden;
    @include transition;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 6px 8px 15px -8px rgba(0,0,0,0.3);
    input {
      width: 100%;
      padding: 8px 12px;
      border:none;
      border-top: 1px solid $bg-color;
      border-bottom: 2px solid #dce4e3;
      @include regular;
      outline: none;
      color: $primary-color;
    }
    .options {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      @include scrollbar;
      padding: 4px 0px 6px 0px;
      &.isSearch {
        max-height: 150px;
      }
      button {
        display: flex;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 6px 12px;
        margin: 0px 6px 4px 6px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: rgba($primary-color, 0.1);
        }
        &.active {
          background-color: rgba($primary-color, 0.1);
          p {
            color: $primary-color;
          }
        }
        p {
          margin: 0px 8px 0px 0px;
          @include semiBold;
          width: calc(100% - 20px);
          text-align: left;
        }

      }

    }
  }
}