@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    .col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .back {
        display: flex;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        color: $primary-color;
        cursor: pointer;
        svg {
          font-size: 22px;
          margin-right: 6px;
        }
        p {
          margin: 0px;
          color: $primary-color;

          text-transform: uppercase;
          font-size: 15px;
          @include regular;
        }
      }
      .date {
        margin-top: 0px;
      }
      .name {
        display: flex;
        align-items: center;
        margin-top: 30px;
        .status {
          margin: 0px;
          border-radius: 25px;
          padding: 6px 10px;
          @include bold;
          color: $black;
        }
        h1 {
          font-size: 24px;
          line-height: 16px;
          @include semiBold;
          color: $primary-color;
          margin: 0px 20px;
        }
      }
    }
  }
  .tabs-links {
    width: calc(100% - 40px);
    max-width: 1200px;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    .tab-link {
      @include reset-btn;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 15px;
      height: 40px;
      border-bottom: 3px solid rgba($primary-color, 0.2);;
      opacity: 0.4;
      @include transition;
      cursor: pointer;
      &.hidden {
        display: none;
      }
      &:hover {
        opacity: 0.8;
        border-bottom: 3px solid rgba($primary-color, 0.2);
      }
      &.active {
        opacity: 1;
        border-bottom: 3px solid rgba($primary-color, 1);
      }
      .icon {
        height: 28px;
        width: 28px;
        @include flex-center;
        margin-right: 4px;
      }
      p {
        margin: 0px;
        font-size: 14px;
        line-height: 14px;
        @include semiBold;
        color: $black;
      }
    }
  }
  .tabs {
    height: calc(100vh - 242px);
    width: 100%;
    overflow: hidden;
    .tabs-container {
      display: flex;
      height: 100%;
      .tab {
        width: 100vw;
        height: 100%;
        overflow: auto;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
        .tab-content {
          width: calc(100% - 40px);
          max-width: 1200px;
          margin: auto;
        }
      }
    }
  }
}