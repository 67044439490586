@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #EAF1F0;
  border:  2px solid #EAF1F0;
  @include flex-center;
  text-decoration: none;
  overflow: hidden;
  @include transition;
  p {
    @include semiBold;
    @include transition;
    text-transform: uppercase;
    color: $primary-color;
    font-size: 16px;
    line-height: 16px;
    margin: 0px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.active {
    background-color: $primary-color;
    border:  2px solid $primary-color;
    p {
      color: #EAF1F0;
    }
  }
}