@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  background-color: $white;
  box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  padding: 20px;
  h4 {
    color: $primary-color;
  }
  .name {
    h4 {
      color: $black;
      margin: 0px;
    }
    margin-bottom: 30px;
  }
  .form {
    @media only screen and (min-width: $md-screen) {
      .row {
        display: flex;
        justify-content: space-between;
        .col {
          width: calc(50% - 10px);
        }
      }
    }
    .container-field {
      margin-bottom: 20px;
    }
  }
  .actions {
    .btn {
      @include btn;
      border-radius: 5px;
      margin-right: 20px;
      text-decoration: none;
    }
    button {
      background-color: transparent;
      border: none;
      @include regular;
      color: $primary-color;
      text-decoration: underline;
    }
  }
}