@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.container {
  width: 100%;
  padding: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .col {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .back {
        display: flex;
        align-items: center;
        padding: 0px;
        border: none;
        background-color: transparent;
        svg {
          color: $primary-color;
          font-size: 22px;
          margin-right: 6px;
        }
        p {
          margin: 0px;
          color: $black;
          font-size: 15px;
          @include bold;
        }
      }
      .date {
        margin-top: 10px;
      }
      .status {
        margin-bottom: 5px;
        border-radius: 25px;
        padding: 6px 10px;
        @include bold;
        color: $black;
      }
      &:last-child {
        width: 150px;
        height: 115px;
        background-color: rgba($primary-color, 0.1);
        border-radius: 13px;
        overflow: hidden;
        @include flex-center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  h1 {
    font-size: 18px;
    @include semiBold;
    color: $black;
  }
}