@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;
  .logo {
    display: flex;
    align-items: center;
    p {
      margin: 0px;
      @include bold;
      font-size: 15px;
      margin-left: 10px;
      color: $black;
    }
  } 
  @media only screen and (min-width: $xl-screen) {
    display: none;
  }
}
