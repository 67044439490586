@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

.signin-form {
  width: 100%;
  .form {
    width: 100%;
    margin-bottom: $margin-lg;
    .container-field {
      width: 100%;
      margin: 0 0 15px 0;
    }
    .message-error {
      @include regular;
      color: $warn;
      font-size: $regular;
      text-align: center;
    }
  }

  .container-link {
    margin-bottom: $margin-md;
    p {
      font-size: $regular;
      color: $text-color;
      a {
        font-size: $regular;
        text-decoration: none;
        color: $primary-color;
      }
    }
  }

  .containerSubmit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      width: 100%;
      @include btn;
    }
    a {
      @include regular;
      color: $text-color;
    }
  }
}
