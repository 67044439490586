@import "../../styles/_variables.scss";
@import "../../styles/mixins";


.pagination {
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media only screen and (min-width: 1260px) {
    right: calc(((100% - 1200px) / 2) + 26px);
  }
  div {
    margin: 0 4px;
    @include flex-center;
    @include regular;
    cursor: pointer;
    color: #333;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 15px;
    height: 30px;
    min-width: 30px;
    &:first-child {
      border: 1px solid rgba($black, 0.5);
      color: rgba($black, 0.5);
    }
    &:last-child {
      border: 1px solid rgba($black, 0.5);
      color: rgba($black, 0.5);
    }
    &.disabled {
      display: none;
    }
    svg {
      font-size: 12px;
    }
    &:hover {
      background-color: rgba($primary-color, 0.1);
      border: 1px solid rgba($black, 0.5);
    }
    &.active {
      background-color: $primary-color;
      color: #fff;
    }
  }
}