@import '../../styles/variables.scss';
@import '../../styles/_mixins.scss';

.overlay-modal{
  position: fixed;
  z-index: 999;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba($primary-color,.66);
}

.modal{
  position: fixed;
  z-index: 1000;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.container {
  border-radius: 8px;
  min-width: 80vw;
  background-color: white;
  touch-action: none;
  @media only screen and (min-width: $xl-screen) {
    border-radius: 8px;
    max-height: 700px;
    min-width: 640px;
  }
  .close {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    padding: 0px;
    position: absolute;
    top: 6px;
    right: 12px;
    @include regular;
    text-decoration: underline;
    color: $primary-color;
    font-size: 30px;
  }
}
